<template>
  <div class="home">
    <custom-section class="section pa-0 pa-md-5">
      <NavbarOne
        class="d-md-none d-lg-none"
        :showNavbar="showNavbar"
        :showCart="showCart"
        :showCartDesktop="showCartDesktop"
        :showSearch="showSearch"
        :showLogo="showLogo"
      >
      </NavbarOne>
      <div class="mb-6 corp-m">
        <div class="card--cart-order">
          <v-form v-model="valid" lazy-validation ref="form">
            <div class="payment-info">
              <v-data-table
                v-model="selected"
                :headers="headers"
                :items="employeeList"
                item-key="id"
                show-select
                class="table-officecare-add pa-3"
                :class="{
                  'table-stick-addnew-exam': employeeList.length > 15,
                }"
                :mobile-breakpoint="0"
                hide-default-footer
                :loading="myloadingvariable"
                sort-by="['employee_number']"
                :sort-desc="[true]"
                :items-per-page="-1"
              >
                <template v-slot:top>
                  <v-row class="pa-3">
                    <v-col cols="12" md="8" class="d-flex align-center">
                      <localized-link to="/company/office-care">
                        <v-icon class="pr-3">mdi-arrow-left</v-icon>
                      </localized-link>

                      <h4>
                        {{ $t("page.employee.add_new_office_care") }}
                      </h4>
                    </v-col>
                    <v-col cols="12" md="4" class="d-flex align-center">
                      <v-text-field
                        class="searchField fontSet pr-3"
                        outlined
                        dense
                        hide-details
                        v-model="search"
                        :label="$t('search') + ' ...'"
                        append-icon="mdi-magnify"
                      ></v-text-field>

                      <div>
                        <v-select
                          class="footerHide pt-0"
                          v-model="perPage"
                          :items="itemShowing"
                          hide-details
                        ></v-select>
                      </div>
                    </v-col>
                    <v-col cols="12" md="4" class="pt-0 pb-0">
                      <v-autocomplete
                        v-model="payload.branch"
                        :items="selectBranch"
                        item-value="id"
                        item-text="name"
                        class="mt-0 uppercase-input fontSet"
                        :label="`${$t('select')} ${$t('branch')}`"
                        outlined
                        required
                        dense
                        :rules="[
                          (v) =>
                            !!v ||
                            `${`${$t('select')} ${$t('branch')}`} ${$t(
                              'is_required'
                            )}`,
                        ]"
                        @change="changeTypeTest()"
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" md="4" class="pt-0 pb-0">
                      <v-menu
                        v-model="menu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            class="mt-0 fontSet"
                            v-model="dateFormatted"
                            :label="$t('field.book_date')"
                            :rules="[
                              (v) =>
                                !!v ||
                                `${$t('field.book_date')} ${$t('is_required')}`,
                            ]"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            hint="DD-MM-YYYY format"
                            outlined
                            dense
                          ></v-text-field>
                        </template>
                        <!-- <v-date-picker
                          :locale="$i18n.locale"
                          v-model="payload.booking_date"
                          no-title
                          @change="updDate()"
                          :allowed-dates="allowedDates"
                          @update:picker-date="pickerUpdate($event)"
                          @input="menu = false"
                          :min="minDate"
                        >
                          <v-spacer></v-spacer>
                        </v-date-picker> -->
                        <c-datepicker
                          :minDate="minDate"
                          :value="payload.booking_date"
                          :holiday="getHoliday"
                          :enableDays="getEnableDays"
                          @changeValue="payload.booking_date = $event"
                          @change="opendatepicker = false"
                        ></c-datepicker>
                      </v-menu>
                    </v-col>
                    <!-- <v-col cols="12" md="4" class="pt-0 pb-0">
                      <v-autocomplete
                        v-model="payload.type"
                        :items="typeTest"
                        class="mt-0 uppercase-input fontSet"
                        :label="`${$t('select')} ${$t('type')}`"
                        outlined
                        required
                        dense
                        @change="changeTypeTest()"
                        :disabled="disabledType"
                        :rules="[
                          (v) =>
                            !!v ||
                            `${$t('select')} ${$t('type')} ${$t(
                              'is_required'
                            )}`,
                        ]"
                      ></v-autocomplete>
                    </v-col> -->
                      <v-col cols="12" md="4" class="pt-0 pb-0">
                        <v-autocomplete
                          class="mt-0 uppercase-input fontSet"
                          v-model="payload.selectGender"
                          :items="gender"
                          :item-text="
                            $i18n.locale == 'en'
                              ? `name`
                              : `name_${$i18n.locale}`
                          "
                          item-value="name"
                          :rules="[
                            (v) =>
                              !!v ||
                              `${$t('field.gender')} ${$t('is_required')}`,
                          ]"
                          :label="$t('field.gender')"
                          outlined
                          required
                          dense
                          v-show="disabledGender == false"
                          @change="changeTypeTest()"
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="12" md="4" class="pt-0 pb-0">
                        <v-autocomplete
                          class="mt-0 uppercase-input fontSet"
                          v-model="payload.levelEmployee"
                          :items="levels"
                          item-text="name"
                          item-value="id"
                          :label="$t('field.level')"
                          outlined
                          dense
                          v-show="disabledLevel == false"
                          @change="changeTypeTest()"
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="12" md="4" class="pt-0 pb-0">
                        <v-autocomplete
                          class="mt-0 uppercase-input fontSet"
                          v-model="payload.selectContract"
                          :items="contract"
                          item-text="name"
                          item-value="id"
                          :label="$t('field.contract')"
                          outlined
                          dense
                          :disabled="disabledContract"
                          v-show="showHideContract == false"
                          @change="changeTypeTest()"
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="12" md="4" class="pt-0 pb-0">
                        <v-autocomplete
                          class="mt-0 uppercase-input fontSet"
                          v-model="payload.selectPackage"
                          :items="packageList"
                          item-text="package_name"
                          item-value="package_id"
                          :label="$t('field.package')"
                          outlined
                          dense
                          :disabled="disabledPackage"
                          v-show="showHidePackage == false"
                          @change="changeTypeTest()"
                        ></v-autocomplete>
                      </v-col>

                    <template
                      v-if="
                        payload.selectPackage != ''
                      "
                    >
                      <v-row class="d-none d-md-flex">
                        <v-col cols="12" md="6" class="pt-3 d-flex align-end">
                          <div class="d-flex align-center justify-start">
                            <v-icon class="mdifont"> mdi-information </v-icon>
                            &nbsp;
                            <p class="labeldatamcu">
                              {{ selected.length }}
                              {{ $t("page.home.label_selected") }}
                            </p>
                          </div>
                        </v-col>
                        <v-col cols="12" md="6" class="pt-3">
                          <div class="d-flex align-center justify-end">
                            <p class="labeltitlemcu">{{ $t("quota") }}</p>
                            &nbsp;
                            <p class="labeldatamcu">
                              {{ quotaRemaining }}
                            </p>
                            &nbsp;
                            <p class="labeltitlemcu">/ {{ quotaTot }}</p>
                          </div>
                          <div class="d-flex align-center justify-end">
                            <p class="labeltitlemcu">
                              {{ $t("page.home.active_until") }}
                            </p>
                            &nbsp;
                            <p class="labeldatamcu">
                              {{ dateExpiredContract }}
                            </p>
                          </div>
                        </v-col>
                      </v-row>
                      <v-row class="d-block d-md-none">
                        <v-col cols="12" class="pt-2 pb-0">
                          <div class="d-flex align-center justify-start">
                            <v-icon class="mdifont"> mdi-information </v-icon>
                            &nbsp;
                            <p class="labeldatamcu">
                              {{ selected.length }}
                              {{ $t("page.home.label_selected") }}
                            </p>
                          </div>
                          <div class="d-flex align-center justify-start">
                            <p class="labeltitlemcu">{{ $t("quota") }}</p>
                            &nbsp;
                            <p class="labeldatamcu">
                              {{ quotaRemaining }}
                            </p>
                            &nbsp;
                            <p class="labeltitlemcu">/ {{ quotaTot }}</p>
                          </div>
                          <div class="d-flex align-center justify-start">
                            <p class="labeltitlemcu">
                              {{ $t("page.home.active_until") }}
                            </p>
                            &nbsp;
                            <p class="labeldatamcu">
                              {{ dateExpiredContract }}
                            </p>
                          </div>
                        </v-col>
                      </v-row>
                    </template>
                  </v-row>
                </template>
                <template v-slot:[`item.employee_number`]="{ item }">
                  <td valign="top" class="pt-2 sticky-c1">
                    {{ item.employee_number }}
                  </td>
                </template>
                <template v-slot:[`item.name`]="{ item }">
                  <td valign="top" class="pt-2 sticky-c2">
                    {{ item.name }}
                  </td>
                </template>
                <template v-slot:[`item.department`]="{ item }">
                  <td valign="top" class="pt-2">{{ item.department }}</td>
                </template>
                <template v-slot:[`item.job_position`]="{ item }">
                  <td valign="top" class="pt-2">
                    {{ item.job_position }}
                  </td>
                </template>
                <template v-slot:[`item.employee_level`]="{ item }">
                  <td valign="top" class="pt-2">
                    {{ item.employee_level }}
                  </td>
                </template>
                <template v-slot:[`item.identity_type`]="{ item }">
                  <td valign="top" class="pt-2">
                    {{ item.identity_type }}
                  </td>
                </template>
                <template v-slot:[`item.identity_number`]="{ item }">
                  <td valign="top" class="pt-2">
                    {{ item.identity_number }}
                  </td>
                </template>
                <template v-slot:[`item.phone_number`]="{ item }">
                  <td valign="top" class="pt-2">
                    {{ item.phone_number }}
                  </td>
                </template>
                <template v-slot:[`item.email`]="{ item }">
                  <td valign="top" class="pt-2">
                    {{ item.email.toUpperCase() }}
                  </td>
                </template>
                <template v-slot:[`item.last_booking`]="{ item }">
                  <td valign="top" class="pt-2">
                    {{
              item.last_booking !== '-' ? formatDate(item.last_booking) : "-"
            }}
                  </td>
                </template>
                <template v-slot:footer>
                  <v-row
                    class="d-flex flex align-center justify-space-between footer-payment"
                  >
                    <v-col cols="12" md="8">
                      <p class="footer-showing">
                        showing {{ itemShowStart }} to {{ itemShowStop }} of
                        {{ itemsTotal }} entries
                      </p>
                    </v-col>
                    <v-col cols="12" md="4" class="d-flex justify-end">
                      <custom-pagination
                        @changePage="(val) => (page = val)"
                        :totalPage="itemLength"
                        :activePage="page"
                      />
                    </v-col>
                  </v-row>
                </template>
              </v-data-table>

              <div class="d-flex align-center justify-center">
                <v-btn
                  :disabled="!valid"
                  class="button-register"
                  @click="validate"
                  color="white"
                  v-show="saveButton == true"
                >
                  {{ $t("save") }}
                </v-btn>
                <v-progress-linear
                  class="mt-6"
                  indeterminate
                  color="teal"
                  v-show="showing == true"
                ></v-progress-linear>
              </div>
            </div>
          </v-form>
        </div>
      </div>
    </custom-section>
  </div>
</template>

<script>
import API from "@/service/api_service.js";
import Auth from "@/service/auth_service";
import NavbarOne from "@/components/v2/topbar/navbar_one.vue";
import moment from "moment-timezone";
import CustomPagination from "@/components/v3/pagination.vue";
import CustomSection from "@/components/v2/section/index.vue";
import CDatepicker from "@/components/v3/date/custom-datepicker.vue";


export default {
  components: { NavbarOne, CustomPagination, CustomSection, CDatepicker },
  data: () => ({
    page: 1,
    options: null,
    itemsTotal: 0,
    itemLength: 1,
    itemShowStart: 0,
    itemShowStop: 0,
    itemShowing: [
      { value: 25, text: "25" },
      { value: 50, text: "50" },
      { value: 70, text: "70" },
      { value: 100, text: "100" },
    ],
    perPage: 25,
    myloadingvariable: true,
    showing: false,
    saveButton: true,
    showNavbar: true,
    showCart: false,
    showCartDesktop: false,
    showSearch: false,
    showLogo: true,
    footerProps: { "items-per-page-options": [25, 50, 70, -1] },
    search: "",
    valid: false,
    modal: false,
    found: false,
    dialog: false,
    typeTest: ["MCU"],
    // typeTest: ["LAB", "MCU"],
    payload: {
      branch: "",
      booking_date: "",
      item: "",
      type: "MCU",
      selectGender: "",
      selectContract: "",
      levelEmployee: "",
      selectPackage:""
    },
    levels: [],
    selected: [],
    selectBranch: [],
    selectItem: [],
    packageList:[],
    employeeList: [],
    disabledType: true,
    disabledGender: false,
    disabledContract: true,
    disabledLevel: true,
    showHideContract: true,
    showHidePackage: true,
    disabledPackage: true,
    headers: [],
    setInput: [],
    gender: [],
    contract: [],
    maxDisplay: 1,
    dateExpiredContract: "",
    quotaRemaining: "",
    quotaTot: "",
    // packageID: "",
    menu: false,
    genderAll: { id: 1, code: "A", name: "ALL", name_id: "SEMUA" },
    availableDates: [],
    ignoreRegisSameDay: false,
  }),
  created() {
    this.headers = [
      {
        text: this.$t("field.employee_number"),
        align: "center",
        // sortable: false,
        value: "employee_number",
        fixed: true,
        class: "sticky-h1",
      },
      {
        text: this.$t("field.name"),
        value: "name",
        // sortable: false,
        align: "center",
        fixed: true,
        class: "sticky-h2",
      },
      {
        text: this.$t("field.department"),
        value: "department",
        // sortable: false,
        align: "center",
      },
      {
        text: this.$t("field.jobpost"),
        value: "job_position",
        // sortable: false,
        align: "center",
      },
      {
        text: this.$t("field.level"),
        value: "employee_level",
        // sortable: false,
        align: "center",
      },
      {
        text: this.$t("field.identity_type"),
        value: "identity_type",
        sortable: false,
        align: "center",
      },
      {
        text: this.$t("field.id_number"),
        value: "identity_number",
        sortable: false,
        align: "center",
      },
      {
        text: this.$t("field.mobile_phone"),
        value: "phone_number",
        sortable: false,
        align: "center",
      },
      {
        text: this.$t("field.email"),
        value: "email",
        sortable: false,
        align: "center",
      },
      {
        text: this.$t("field.last_booking"),
        value: "last_booking",
        sortable: false,
        align: "center",
      },
    ];
    this.getDataEmployee();
  },
  watch: {
    "payload.branch": async function () {
      const minDate = this.minDate; // Replace with your minimum date or leave as null
      const maxDate = null; // Replace with your maximum date or leave as null

      this.payload.booking_date = this.findClosestValidDate(minDate, maxDate);
    },
    page: function () {
      this.getDataEmployee();
    },
    perPage: function () {
      if (this.page != 1) {
        this.page = 1;
      } else {
        this.getDataEmployee();
      }
    },
    search: function () {
      this.page = 1;
      this.getDataEmployee();
    },
  },
  computed: {
     // start holiday
     getHoliday() {
      const indexPlant = this.selectBranch.findIndex(
        res => res.id == this.payload.branch
      );
      if (indexPlant > -1) {
        return this.selectBranch[indexPlant].upcoming_holidays;
      }
      return [];
    },
    // end holiday

    // start enable days
    getEnableDays() {
      const indexPlant = this.selectBranch.findIndex(
        res => res.id == this.payload.branch
      );
      let enableDays = [];
      if (indexPlant > -1) {
        const days = [
          "Minggu",
          "Senin",
          "Selasa",
          "Rabu",
          "Kamis",
          "Jumat",
          "Sabtu",
        ];
        const { opening_hours } = this.selectBranch[indexPlant];
        for (let i = 0; i < days.length; i++) {
          const indexDays = opening_hours.findIndex(
            item => item.day == days[i]
          );
          if (indexDays > -1) {
            enableDays.push(i);
          }
        }
      }
      return enableDays;
    },
    // end enable days
   
    minDate() {
      let currentTime = moment();
      // check timezone time
      if (this.payload.branch != null && this.payload.branch != "") {
        const indexBranch = this.selectBranch.findIndex(
          item => item.id == this.payload.branch
        );
        if (indexBranch > -1) {
          const timezone = moment.tz.guess();
          if (this.selectBranch[indexBranch].timezone_area == timezone) {
            currentTime = moment();
          } else {
            const now = moment().format("HH:mm:ss");
            currentTime = moment(
              this.convertTime(
                now,
                moment.tz.guess(),
                this.selectBranch[indexBranch].timezone_area
              ),
              "YYYY-MM-DD HH:mm:ss"
            );
          }
        }
      }
      // Set the start and end time boundaries
      let startTime = "";
      let endTime = "";
      const indexPlant = this.selectBranch.findIndex(
        res => res.id == this.payload.branch
      );
      if (indexPlant > -1) {
        const { opening_hours } = this.selectBranch[indexPlant];
        const indexByDayName = opening_hours.findIndex(
          item => item.day == currentTime.locale("ID").format("dddd")
        );
        if (indexByDayName > -1) {
          const close = opening_hours[indexByDayName].hours
            .split("-")[1]
            .replace(":00", "");
          startTime = moment()
            .hour(0) // mulai jam 00:00
            .minute(0)
            .second(0);
          endTime = moment().hour(close).minute(0).second(0);
        }
      }
      // Check if the current time is between the start and end time
      const isWithinTimeRange = indexPlant > -1 ? currentTime.isBetween(startTime, endTime) : true;

      // Output the result
      if (!isWithinTimeRange) {
        return moment()
          .add(currentTime.day() === 6 ? 2 : 1, "days")
          .format("YYYY-MM-DD");
      } else {
        return moment().format("YYYY-MM-DD");
      }
    },
    dateFormatted() {
      return this.formatDate(this.payload.booking_date);
    },
  },
  async mounted() {
    this.$store.commit("setLoading", true);
    // gender
    await API.get(`${process.env.VUE_APP_API_TRUST}gender/get`, {}).then(
      (res) => {
        if (res.statusCode == 200) {
          this.gender = res.results;
          this.gender = [...this.gender, this.genderAll];
          this.gender = this.gender.sort((a, b) => this.sortByName(a, b));
        }
      }
    );

    // levels
    API.get(`${process.env.VUE_APP_API_TRUST}employee/get-levels`, {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Auth.getToken()}`,
    }).then((res) => {
      if (res.statusCode == 200) {
        this.levels = res.results;
      }
    });
    // contract
    await API.get(
      `${process.env.VUE_APP_API_TRUST}business-partner-contract/get `,
      {
        Authorization: `Bearer ${Auth.getToken()}`,
      }
    ).then((res) => {
      if (res.statusCode == 200) {
        this.contract = res.results.sort((a, b) => this.sortByName(a, b));
      }
    });

    // branch
    await API.get(`${process.env.VUE_APP_API_TRUST}plant/get?show_opening_hours=true `, {
      Authorization: `Bearer ${Auth.getToken()}`,
    }).then((resp) => {
      this.selectBranch = resp.results;
    });

    this.payload.booking_date = moment().format("YYYY-MM-DD");

    this.$store.commit("setLoading", false);
  },
  methods: {
    // start near date
    findClosestValidDate(minDate, maxDate) {
      const startDate = moment(this.payload.booking_date);
      const getEnableDays = this.getEnableDays;

      // Exclude the holiday on August 17th, 2023 (which is a Thursday)
      // getEnableDays.push(moment("2023-08-17").day());

      // return same day when enable
      if (
        getEnableDays.includes(startDate.day()) &&
        (!minDate || startDate.isSameOrAfter(minDate)) &&
        (!maxDate || startDate.isSameOrBefore(maxDate))
      ) {
        return startDate.format("YYYY-MM-DD");
      }
      let closestDateBefore = null;
      let closestDateAfter = null;
      let daysDifferenceBefore = Infinity;
      let daysDifferenceAfter = Infinity;

      for (let daysToAdd = 1; daysToAdd <= 7; daysToAdd++) {
        const candidateDateBefore = startDate
          .clone()
          .subtract(daysToAdd, "days");
        const candidateDateAfter = startDate.clone().add(daysToAdd, "days");

        const isAfterMinDateBefore =
          !minDate || candidateDateBefore.isSameOrAfter(minDate);
        const isBeforeMaxDateBefore =
          !maxDate || candidateDateBefore.isSameOrBefore(maxDate);

        if (
          getEnableDays.includes(candidateDateBefore.day()) &&
          isAfterMinDateBefore &&
          isBeforeMaxDateBefore
        ) {
          const currentDifference = Math.abs(
            candidateDateBefore.diff(startDate, "days")
          );
          if (currentDifference < daysDifferenceBefore) {
            closestDateBefore = candidateDateBefore;
            daysDifferenceBefore = currentDifference;
          }
        }

        const isAfterMinDateAfter =
          !minDate || candidateDateAfter.isSameOrAfter(minDate);
        const isBeforeMaxDateAfter =
          !maxDate || candidateDateAfter.isSameOrBefore(maxDate);

        if (
          getEnableDays.includes(candidateDateAfter.day()) &&
          isAfterMinDateAfter &&
          isBeforeMaxDateAfter
        ) {
          const currentDifference = Math.abs(
            candidateDateAfter.diff(startDate, "days")
          );
          if (currentDifference < daysDifferenceAfter) {
            closestDateAfter = candidateDateAfter;
            daysDifferenceAfter = currentDifference;
          }
        }
      }
      // Compare the closest dates before and after and choose the closer one
      if (closestDateBefore && closestDateAfter) {
        return closestDateBefore.isBefore(closestDateAfter)
          ? closestDateBefore.format("YYYY-MM-DD")
          : closestDateAfter.format("YYYY-MM-DD");
      } else if (closestDateBefore) {
        return closestDateBefore.format("YYYY-MM-DD");
      } else if (closestDateAfter) {
        return closestDateAfter.format("YYYY-MM-DD");
      } else {
        return null;
      }
    },
    // end near date

    // start convert time
    convertTime(timeString, sourceTimeZone, targetTimeZone) {
      // Parse the original time string in the source timezone
      const originalTime = moment.tz(timeString, "HH:mm:ss", sourceTimeZone);

      // Convert the time to the target timezone
      const convertedTime = originalTime.clone().tz(targetTimeZone);

      // Format the converted time as "HH:mm:ss"
      const formattedTime = convertedTime.format("YYYY-MM-DD HH:mm:ss");

      return formattedTime;
    },
    // end convert time

    getDataEmployee() {
      try {
        this.myloadingvariable = true;
        // pagination showing start of entries
        let startPage = (this.page - 1) * this.perPage;
        let showPage = this.perPage;
        this.itemShowStart = startPage + 1;
        this.itemShowStop = startPage + showPage;

        API.get(
          `${process.env.VUE_APP_API_TRUST}employee/get?query=${this.search}&offset=${startPage}&per_page=${showPage}&gender=${this.payload.selectGender}&level=${this.payload.levelEmployee} `,
          {
            Authorization: `Bearer ${Auth.getToken()}`,
          }
        ).then((resp) => {
          var new_data = [];
          var checkData = resp.results.filter((res) => {
            return res.employee_number != null;
          });

          checkData.map(function (e) {
            new_data.push({
              id: typeof e.person_id !== "undefined" ? e.person_id : "",
              employee_number:
                typeof e.employee_number !== "undefined"
                  ? e.employee_number.toUpperCase()
                  : "",
              name: typeof e.name !== "undefined" ? e.name.toUpperCase() : "",
              department:
                e.department !== null ? e.department.toUpperCase() : "-",
              job_position:
                e.job_position !== null ? e.job_position.toUpperCase() : "-",
              employee_level:
                e.employee_level !== null ? e.employee_level : "-",
              identity_type:
                e.identity[0] !== null ? e.identity[0].identity_type : "-",
              identity_number:
                e.identity[0] !== null ? e.identity[0].identity_number : "-",
              phone_number:
                typeof e.phone_number !== "undefined" ? e.phone_number : "",
              email:
                typeof e.email !== "undefined" ? e.email.toUpperCase() : "",
              last_booking:
                 e.last_booking !== null
                  ? e.last_booking.toUpperCase()
                  : "-",
            });
          });

          this.employeeList = new_data;

          this.itemsTotal = resp.total;
          this.itemLength = Math.ceil(this.itemsTotal / showPage);
          if (this.itemShowStop < 0) {
            this.itemShowStop = resp.total;
          }
          if (this.itemShowStop > resp.total) {
            this.itemShowStop = resp.total;
          }
          this.myloadingvariable = false;
        });
      } catch (error) {
        console.log(error);
      }
    },
    changeTypeTest() {
      if (
        this.payload.branch != "" &&
        this.payload.selectGender == "" &&
        this.payload.levelEmployee == "" &&
        this.payload.selectContract == "" &&
        this.payload.selectPackage == ""
      ) {
        this.disabledType = false;
        this.disabledGender = false;
        this.disabledContract = true;
        this.showHideContract = true;
        this.disabledLevel = true;
        this.showHidePackage= true;
        this.disabledPackage= true;
      } else if (
        this.payload.branch != "" &&
        this.payload.selectGender != "" &&
        this.payload.levelEmployee == "" &&
        this.payload.selectContract == "" &&
        this.payload.selectPackage == ""
      ) {
        API.get(
          `${process.env.VUE_APP_API_TRUST}employee/get?gender=${this.payload.selectGender} `,
          {
            Authorization: `Bearer ${Auth.getToken()}`,
          }
        ).then((resp) => {
          var new_data = [];
          var checkData = resp.results.filter((res) => {
            return res.employee_number != null;
          });
          checkData.map(function (e) {
            new_data.push({
              id: typeof e.person_id !== "undefined" ? e.person_id : "",
              employee_number:
                typeof e.employee_number !== "undefined"
                  ? e.employee_number.toUpperCase()
                  : "",
              name: typeof e.name !== "undefined" ? e.name.toUpperCase() : "",
              department:
                e.department !== null ? e.department.toUpperCase() : "-",
              job_position:
                e.job_position !== null ? e.job_position.toUpperCase() : "-",
              employee_level:
                e.employee_level !== null ? e.employee_level : "-",
              identity_type:
                e.identity[0] !== null ? e.identity[0].identity_type : "-",
              identity_number:
                e.identity[0] !== null ? e.identity[0].identity_number : "-",
              phone_number:
                typeof e.phone_number !== "undefined" ? e.phone_number : "",
              email:
                typeof e.email !== "undefined" ? e.email.toUpperCase() : "",
              last_booking:
                 e.last_booking !== null
                  ? e.last_booking.toUpperCase()
                  : "-",
            });
          });

          this.employeeList = new_data;
        });

        this.disabledType = false;
        this.disabledGender = false;
          this.disabledContract = false;
          this.showHideContract = false;
          this.disabledLevel = false;
          this.showHidePackage= false;
        this.disabledPackage= true;
          this.selected = [];

          // contract
          API.get(
            `${process.env.VUE_APP_API_TRUST}business-partner-contract/get?gender=${this.payload.selectGender} `,
            {
              Authorization: `Bearer ${Auth.getToken()}`,
            }
          ).then((res) => {
            if (res.statusCode == 200) {
              this.contract = res.results;
             
            }
          });
        
      } else if (
        this.payload.branch != "" &&
        this.payload.selectGender != "" &&
        this.payload.levelEmployee != "" &&
        this.payload.selectContract == "" &&
        this.payload.selectPackage == ""
      ) {
        API.get(
          `${process.env.VUE_APP_API_TRUST}employee/get?gender=${this.payload.selectGender}&level=${this.payload.levelEmployee} `,
          {
            Authorization: `Bearer ${Auth.getToken()}`,
          }
        ).then((resp) => {
          var new_data = [];
          var checkData = resp.results.filter((res) => {
            return res.employee_number != null;
          });
          checkData.map(function (e) {
            new_data.push({
              id: typeof e.person_id !== "undefined" ? e.person_id : "",
              employee_number:
                typeof e.employee_number !== "undefined"
                  ? e.employee_number.toUpperCase()
                  : "",
              name: typeof e.name !== "undefined" ? e.name.toUpperCase() : "",
              department:
                e.department !== null ? e.department.toUpperCase() : "-",
              job_position:
                e.job_position !== null ? e.job_position.toUpperCase() : "-",
              employee_level:
                e.employee_level !== null ? e.employee_level : "-",
              identity_type:
                e.identity[0] !== null ? e.identity[0].identity_type : "-",
              identity_number:
                e.identity[0] !== null ? e.identity[0].identity_number : "-",
              phone_number:
                typeof e.phone_number !== "undefined" ? e.phone_number : "",
              email:
                typeof e.email !== "undefined" ? e.email.toUpperCase() : "",
              last_booking:
                 e.last_booking !== null
                  ? e.last_booking.toUpperCase()
                  : "-",
            });
          });

          this.employeeList = new_data;
        });

        // contract
        API.get(
          `${process.env.VUE_APP_API_TRUST}business-partner-contract/get?gender=${this.payload.selectGender}&level=${this.payload.levelEmployee} `,
          {
            Authorization: `Bearer ${Auth.getToken()}`,
          }
        ).then((res) => {
          if (res.statusCode == 200) {
            this.contract = res.results;
           
          }
        });

        this.disabledType = false;
        this.disabledGender = false;

       
          this.disabledContract = false;
          this.showHideContract = false;
          this.disabledLevel = false;
          this.showHidePackage= false;
        this.disabledPackage= true;
          this.selected = [];
        
      } else if (
        this.payload.branch != "" &&
        this.payload.selectGender == "" &&
        this.payload.levelEmployee != "" &&
        this.payload.selectContract == "" &&
        this.payload.selectPackage == ""
      ) {
        API.get(
          `${process.env.VUE_APP_API_TRUST}employee/get?gender=${this.payload.selectGender}&level=${this.payload.levelEmployee} `,
          {
            Authorization: `Bearer ${Auth.getToken()}`,
          }
        ).then((resp) => {
          var new_data = [];
          var checkData = resp.results.filter((res) => {
            return res.employee_number != null;
          });
          checkData.map(function (e) {
            new_data.push({
              id: typeof e.person_id !== "undefined" ? e.person_id : "",
              employee_number:
                typeof e.employee_number !== "undefined"
                  ? e.employee_number.toUpperCase()
                  : "",
              name: typeof e.name !== "undefined" ? e.name.toUpperCase() : "",
              department:
                e.department !== null ? e.department.toUpperCase() : "-",
              job_position:
                e.job_position !== null ? e.job_position.toUpperCase() : "-",
              employee_level:
                e.employee_level !== null ? e.employee_level : "-",
              identity_type:
                e.identity[0] !== null ? e.identity[0].identity_type : "-",
              identity_number:
                e.identity[0] !== null ? e.identity[0].identity_number : "-",
              phone_number:
                typeof e.phone_number !== "undefined" ? e.phone_number : "",
              email:
                typeof e.email !== "undefined" ? e.email.toUpperCase() : "",
              last_booking:
                 e.last_booking !== null
                  ? e.last_booking.toUpperCase()
                  : "-",
            });
          });

          this.employeeList = new_data;
        });

        // contract
        API.get(
          `${process.env.VUE_APP_API_TRUST}business-partner-contract/get?gender=${this.payload.selectGender}&level=${this.payload.levelEmployee} `,
          {
            Authorization: `Bearer ${Auth.getToken()}`,
          }
        ).then((res) => {
          if (res.statusCode == 200) {
            this.contract = res.results;
          }
        });

        this.disabledType = false;
        this.disabledGender = false;
          this.disabledContract = false;
          this.showHideContract = false;
          this.disabledLevel = false;
          this.showHidePackage= false;
        this.disabledPackage= true;
          this.selected = [];
        
      }  else if (
        this.payload.branch != "" &&
        this.payload.selectGender != "" &&
        this.payload.levelEmployee == "" &&
        this.payload.selectContract != "" &&
        this.payload.selectPackage == ""
      ) {
        API.get(
          `${process.env.VUE_APP_API_TRUST}employee/get?gender=${this.payload.selectGender} `,
          {
            Authorization: `Bearer ${Auth.getToken()}`,
          }
        ).then((resp) => {
          var new_data = [];
          var checkData = resp.results.filter((res) => {
            return res.employee_number != null;
          });
          checkData.map(function (e) {
            new_data.push({
              id: typeof e.person_id !== "undefined" ? e.person_id : "",
              employee_number:
                typeof e.employee_number !== "undefined"
                  ? e.employee_number.toUpperCase()
                  : "",
              name: typeof e.name !== "undefined" ? e.name.toUpperCase() : "",
              department:
                e.department !== null ? e.department.toUpperCase() : "-",
              job_position:
                e.job_position !== null ? e.job_position.toUpperCase() : "-",
              employee_level:
                e.employee_level !== null ? e.employee_level : "-",
              identity_type:
                e.identity[0] !== null ? e.identity[0].identity_type : "-",
              identity_number:
                e.identity[0] !== null ? e.identity[0].identity_number : "-",
              phone_number:
                typeof e.phone_number !== "undefined" ? e.phone_number : "",
              email:
                typeof e.email !== "undefined" ? e.email.toUpperCase() : "",
              last_booking:
                 e.last_booking !== null
                  ? e.last_booking.toUpperCase()
                  : "-",
            });
          });

          this.employeeList = new_data;
        });

        this.disabledType = false;
        this.disabledGender = false;

       
          this.disabledContract = false;
          this.showHideContract = false;
          this.disabledLevel = false;
          this.showHidePackage= false;
        this.disabledPackage= false;
          this.selected = [];

          // contract
          API.get(
            `${process.env.VUE_APP_API_TRUST}business-partner-contract/get?gender=${this.payload.selectGender}`,
            {
              Authorization: `Bearer ${Auth.getToken()}`,
            }
          ).then((res) => {
            if (res.statusCode == 200) {
              this.contract = res.results;
              const filterNewData = res.results.filter((dataContractNew) => {
                return dataContractNew.id == this.payload.selectContract;
              });
            
              if (filterNewData.length == 0) {
              
                this.packageList = [];
              } else {
                this.packageList = filterNewData[0].detail;
              }
            }
          });
        
      } 
      else if (
        this.payload.branch != "" &&
        this.payload.selectGender != "" &&
        this.payload.levelEmployee == "" &&
        this.payload.selectContract != "" &&
        this.payload.selectPackage != ""
      ) {
        API.get(
          `${process.env.VUE_APP_API_TRUST}employee/get?gender=${this.payload.selectGender} `,
          {
            Authorization: `Bearer ${Auth.getToken()}`,
          }
        ).then((resp) => {
          var new_data = [];
          var checkData = resp.results.filter((res) => {
            return res.employee_number != null;
          });
          checkData.map(function (e) {
            new_data.push({
              id: typeof e.person_id !== "undefined" ? e.person_id : "",
              employee_number:
                typeof e.employee_number !== "undefined"
                  ? e.employee_number.toUpperCase()
                  : "",
              name: typeof e.name !== "undefined" ? e.name.toUpperCase() : "",
              department:
                e.department !== null ? e.department.toUpperCase() : "-",
              job_position:
                e.job_position !== null ? e.job_position.toUpperCase() : "-",
              employee_level:
                e.employee_level !== null ? e.employee_level : "-",
              identity_type:
                e.identity[0] !== null ? e.identity[0].identity_type : "-",
              identity_number:
                e.identity[0] !== null ? e.identity[0].identity_number : "-",
              phone_number:
                typeof e.phone_number !== "undefined" ? e.phone_number : "",
              email:
                typeof e.email !== "undefined" ? e.email.toUpperCase() : "",
              last_booking:
                 e.last_booking !== null
                  ? e.last_booking.toUpperCase()
                  : "-",
            });
          });

          this.employeeList = new_data;
        });

        this.disabledType = false;
        this.disabledGender = false;

       
          this.disabledContract = false;
          this.showHideContract = false;
          this.disabledLevel = false;
          this.showHidePackage= false;
        this.disabledPackage= false;
          this.selected = [];

          // contract
          API.get(
            `${process.env.VUE_APP_API_TRUST}business-partner-contract/get?gender=${this.payload.selectGender}`,
            {
              Authorization: `Bearer ${Auth.getToken()}`,
            }
          ).then((res) => {
            if (res.statusCode == 200) {
              this.contract = res.results;
              const filterNewData = res.results.filter((dataContractNew) => {
                return dataContractNew.id == this.payload.selectContract;
              });
            
              if (filterNewData.length == 0) {
                this.dateExpiredContract = "-";
                this.quotaRemaining = 0;
                this.quotaTot = 0;
                this.packageList = [];
              } else {
                this.packageList = filterNewData[0].detail;
                const filterNewDataPackage = filterNewData[0].detail.filter((dataPackageNew) => {
                  return dataPackageNew.package_id == this.payload.selectPackage;
                });
                this.dateExpiredContract = this.formatDate(
                  filterNewData[0].end_date
                );
                this.quotaRemaining = parseInt(filterNewDataPackage[0].balance);
                this.quotaTot = parseInt(filterNewDataPackage[0].quota);
              }
            }
          });
        
      }
      else if (
        this.payload.branch != "" &&
        this.payload.selectGender != "" &&
        this.payload.levelEmployee != "" &&
        this.payload.selectContract != "" &&
        this.payload.selectPackage == ""
      ) {
        API.get(
          `${process.env.VUE_APP_API_TRUST}employee/get?gender=${this.payload.selectGender}&level=${this.payload.levelEmployee} `,
          {
            Authorization: `Bearer ${Auth.getToken()}`,
          }
        ).then((resp) => {
          var new_data = [];
          var checkData = resp.results.filter((res) => {
            return res.employee_number != null;
          });
          checkData.map(function (e) {
            new_data.push({
              id: typeof e.person_id !== "undefined" ? e.person_id : "",
              employee_number:
                typeof e.employee_number !== "undefined"
                  ? e.employee_number.toUpperCase()
                  : "",
              name: typeof e.name !== "undefined" ? e.name.toUpperCase() : "",
              department:
                e.department !== null ? e.department.toUpperCase() : "-",
              job_position:
                e.job_position !== null ? e.job_position.toUpperCase() : "-",
              employee_level:
                e.employee_level !== null ? e.employee_level : "-",
              identity_type:
                e.identity[0] !== null ? e.identity[0].identity_type : "-",
              identity_number:
                e.identity[0] !== null ? e.identity[0].identity_number : "-",
              phone_number:
                typeof e.phone_number !== "undefined" ? e.phone_number : "",
              email:
                typeof e.email !== "undefined" ? e.email.toUpperCase() : "",
              last_booking:
                 e.last_booking !== null
                  ? e.last_booking.toUpperCase()
                  : "-",
            });
          });

          this.employeeList = new_data;
        });

        // contract
        API.get(
          `${process.env.VUE_APP_API_TRUST}business-partner-contract/get?gender=${this.payload.selectGender}&level=${this.payload.levelEmployee} `,
          {
            Authorization: `Bearer ${Auth.getToken()}`,
          }
        ).then((res) => {
          if (res.statusCode == 200) {
            this.contract = res.results;
            const filterNewData = res.results.filter((dataContractNew) => {
              return dataContractNew.id == this.payload.selectContract;
            });

            if (filterNewData.length == 0) {
              this.packageList = [];
            } else {
              this.packageList = filterNewData[0].detail;
            }
          }
        });

        this.disabledType = false;
        this.disabledGender = false;

      
          this.disabledContract = false;
          this.showHideContract = false;
          this.disabledLevel = false;
          this.showHidePackage= false;
        this.disabledPackage= false;
          this.selected = [];
        
      } 
      else if (
        this.payload.branch != "" &&
        this.payload.selectGender != "" &&
        this.payload.levelEmployee != "" &&
        this.payload.selectContract != "" &&
        this.payload.selectPackage != ""
      ) {
        API.get(
          `${process.env.VUE_APP_API_TRUST}employee/get?gender=${this.payload.selectGender}&level=${this.payload.levelEmployee} `,
          {
            Authorization: `Bearer ${Auth.getToken()}`,
          }
        ).then((resp) => {
          var new_data = [];
          var checkData = resp.results.filter((res) => {
            return res.employee_number != null;
          });
          checkData.map(function (e) {
            new_data.push({
              id: typeof e.person_id !== "undefined" ? e.person_id : "",
              employee_number:
                typeof e.employee_number !== "undefined"
                  ? e.employee_number.toUpperCase()
                  : "",
              name: typeof e.name !== "undefined" ? e.name.toUpperCase() : "",
              department:
                e.department !== null ? e.department.toUpperCase() : "-",
              job_position:
                e.job_position !== null ? e.job_position.toUpperCase() : "-",
              employee_level:
                e.employee_level !== null ? e.employee_level : "-",
              identity_type:
                e.identity[0] !== null ? e.identity[0].identity_type : "-",
              identity_number:
                e.identity[0] !== null ? e.identity[0].identity_number : "-",
              phone_number:
                typeof e.phone_number !== "undefined" ? e.phone_number : "",
              email:
                typeof e.email !== "undefined" ? e.email.toUpperCase() : "",
              last_booking:
                 e.last_booking !== null
                  ? e.last_booking.toUpperCase()
                  : "-",
            });
          });

          this.employeeList = new_data;
        });

        // contract
        API.get(
          `${process.env.VUE_APP_API_TRUST}business-partner-contract/get?gender=${this.payload.selectGender}&level=${this.payload.levelEmployee} `,
          {
            Authorization: `Bearer ${Auth.getToken()}`,
          }
        ).then((res) => {
          if (res.statusCode == 200) {
            this.contract = res.results;
            const filterNewData = res.results.filter((dataContractNew) => {
              return dataContractNew.id == this.payload.selectContract;
            });

            if (filterNewData.length == 0) {
              this.dateExpiredContract = "-";
              this.quotaRemaining = 0;
              this.quotaTot = 0;
              this.packageList = [];
            } else {
              this.packageList = filterNewData[0].detail;
                const filterNewDataPackage = filterNewData[0].detail.filter((dataPackageNew) => {
                  return dataPackageNew.package_id == this.payload.selectPackage;
                });
                this.dateExpiredContract = this.formatDate(
                  filterNewData[0].end_date
                );
                this.quotaRemaining = parseInt(filterNewDataPackage[0].balance);
                this.quotaTot = parseInt(filterNewDataPackage[0].quota);
            }
          }
        });

        this.disabledType = false;
        this.disabledGender = false;

      
          this.disabledContract = false;
          this.showHideContract = false;
          this.disabledLevel = false;
          this.showHidePackage= false;
        this.disabledPackage= false;
          this.selected = [];
        
      }
      else {
        this.disabledType = true;
        this.disabledGender = true;
        this.disabledContract = true;
        this.disabledLevel = true;
        this.disabledPackage= true;
      }
    },
    async validate() {
      if (this.$refs.form.validate() == true) {
        this.setInput = [];
        this.saveButton = false;
        this.showing = true;

        if (this.selected.length > 0) {
          
            if (
              this.payload.selectPackage == null ||
              this.payload.selectPackage == ""
            ) {
              this.$swal({
                text: this.$t("field.select_package_first"),
                showCancelButton: false,
                confirmButtonColor: "#1A428A",
                confirmButtonText: this.$t("ok"),
              });
               this.saveButton = true;
               this.showing = false;
            } else {
              // await API.get(
              //   `${process.env.VUE_APP_API_TRUST}business-partner-contract/get?gender=${this.payload.selectGender}&level=${this.payload.levelEmployee} `,
              //   {
              //     Authorization: `Bearer ${Auth.getToken()}`,
              //   }
              // ).then((res) => {
              //   if (res.statusCode == 200) {
              //     const filterNewDatas = res.results.filter((dataContracts) => {
              //       return dataContracts.id == this.payload.selectContract;
              //     });

              //     this.packageID = filterNewDatas[0].package_id;
              //   }
              // });

              let newPerson = [];
              this.selected.forEach((element) => {
                newPerson = [...newPerson, element.id];
              });

              // start check booking time
              let currentTime = moment();
              // check timezone time
              if (this.payload.branch != null && this.payload.branch != "") {
                const indexBranch = this.selectBranch.findIndex(
                  (item) => item.id == this.payload.branch
                );
                if (indexBranch > -1) {
                  const timezone = moment.tz.guess();
                  if (this.selectBranch[indexBranch].timezone_area == timezone) {
                    currentTime = moment();
                  } else {
                    const now = moment().format("HH:mm:ss");
                    currentTime = moment(
                      this.convertTime(
                        now,
                        moment.tz.guess(),
                        this.selectBranch[indexBranch].timezone_area
                      ),
                      "YYYY-MM-DD HH:mm:ss"
                    );
                  }
                }
              }

              // Set the start and end time boundaries
              let startTime = "";
              let endTime = "";

              const indexPlant = this.selectBranch.findIndex(
                res => res.id == this.payload.branch
              );
              if (indexPlant > -1) {
                const { opening_hours } = this.selectBranch[indexPlant];
                const indexByDayName = opening_hours.findIndex(
                  item => item.day == currentTime.locale("ID").format("dddd")
                );
                if (indexByDayName > -1) {
                  const close = opening_hours[indexByDayName].hours
                    .split("-")[1]
                    .replace(":00", "");
                  startTime = moment()
                    .hour(close - 2) // mulai jam 2 jam sebelum tutup warning
                    .minute(0)
                    .second(0);
                  endTime = moment().hour(close).minute(0).second(0);
                }
              }
              
              // Check if the current time is between the start and end time
              const isWithinTimeRange = currentTime.isBetween(
                startTime,
                endTime
              );

              if (
                !this.ignoreRegisSameDay &&
                isWithinTimeRange &&
                currentTime.format("DD-MM-YYYY") ==
                  moment(this.payload.booking_date).format("DD-MM-YYYY")
              ) {
                await this.$swal({
                  icon: "warning",
                  text: `${this.$t(
                "page.cart.notif_open_hours_start"
              )} ${endTime.format("HH:mm")}. ${this.$t(
                "page.cart.notif_open_hours_end"
              )}`,
                  confirmButtonColor: "#00a4b3",
                  showCancelButton: true,
                  cancelButtonColor: "#ff0000",
                  confirmButtonText: this.$t("oke"),
                  cancelButtonText: this.$t("cancel"),
                  allowOutsideClick: false,
                }).then((result) => {
                  if (result.isConfirmed) {
                    this.ignoreRegisSameDay = true;
                    return "exit";
                  }
                });
              } else {
                this.ignoreRegisSameDay = true;
              }
              // end check book time

              this.setInput = [
                ...this.setInput,
                {
                  plant_id: this.payload.branch,
                  contract_id: this.payload.selectContract,
                  person_ids: newPerson,
                  item_ids: [this.payload.selectPackage],
                  type: this.payload.type,
                  booking_date: this.payload.booking_date,
                },
              ];
              API.post(
                `${process.env.VUE_APP_API_TRUST}employee/book`,
                {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${Auth.getToken()}`,
                },
                this.setInput
              )
                .then((res) => {
                  if (res.statusCode === 200) {
                    // this.showing = false;
                    this.showing = true;
                    this.saveButton = false;
                    // this.saveShowing = true;
                    this.setInput = [];
                   
                    this.$router.push(
                      `/${this.$i18n.locale}/company/office-care`
                    );
                  } else if (res.statusCode == 400) {
                    Object.keys(res.errors).forEach((element) => {
                      this.toast(
                        "error",
                        typeof res.errors[element] == "object"
                          ? res.errors[element][0]
                          : res.errors[element]
                      );
                    });
                    this.setInput = [];
                    this.saveButton = true;
                    this.showing = false;
                  } else if (res.statusCode == 403) {
                    // this.toast("error", res.message);
                    Object.keys(res.errors).forEach((element) => {
                      this.toast(
                        "error",
                        typeof res.errors[element] == "object"
                          ? res.errors[element][0]
                          : res.errors[element]
                      );
                    });
                    this.setInput = [];
                    this.saveButton = true;
                    this.showing = false;
                  } else {
                    this.$swal({
                      icon: "error",
                      text: res.message,
                      //text: this.$t("error_try_again_latter"),
                    });
                    this.saveButton = true;
                    this.showing = false;
                  }
                })
                .catch(() => {
                  this.$swal({
                    icon: "error",
                    text: "Error Add Employee!",
                  });
                  this.saveButton = true;
                  this.showing = false;
                });
            }
          
        }
        else{
          this.saveButton = true;
          this.showing = false;
        }
      }
      else{
        this.setInput = [];
        this.saveButton = true;
        this.showing = false;
      }
    },

    toast(status, messages) {
      this.$swal
        .mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", this.$swal.stopTimer);
            toast.addEventListener("mouseleave", this.$swal.resumeTimer);
          },
        })
        .fire({
          icon: status,
          title: messages,
        });
    },

    sortByName(a, b) {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    },
    formatDate(date) {
      if (!date) return null;
      return moment(date)
        .locale(this.$i18n.locale)
        .format("DD-MMM-YYYY")
        .toUpperCase();
    },
    updDate() {
      this.$store.state.date = moment(this.dateFormatted)
        .format("YYYY-MM-DD")
        .toUpperCase();
    },
    // start available date
    allowedDates(a) {
      return this.availableDates.includes(a);
    },
    pickerUpdate: function (val) {
      let totalDay = moment(val, "YYYY-MM").daysInMonth();

      let availableDates = [];

      let monthNow = moment().format("M");
      let monthSelected = moment(val).format("M");
      let day;

      if (monthNow == monthSelected) day = moment().format("D");
      else day = 1;

      for (let i = day; i <= totalDay; i++) {
        let date = moment()
          .month(val.split("-")[1] - 1)
          .date(i)
          .format("YYYY-MM-DD");
        if (moment(date).day() !== 0) availableDates.push(date);
      }
      this.availableDates = availableDates;
      this.allowedDates();
    },
  },
};
</script>

<style>
.area-select {
  margin-left: 10px;
  margin-right: 10px;
  width: 100%;
}
.uppercase-input input,
.v-input {
  text-transform: uppercase;
}

.table-officecare-add thead {
  background: #ddd !important;
  font-weight: bold;
  color: #000 !important;
}

.table-officecare-add thead tr th {
  height: 38px !important;
  white-space: nowrap;
}

.table-officecare-add tbody tr td {
  font-size: 12px !important;
  height: 35px !important;
  white-space: nowrap;
}

.table-officecare-add tbody tr:hover {
  background-color: #eee !important;
}
</style>
<style lang="scss" scoped>
@import "@/assets/css/custom.scss";
.home {
  display: flex;
  flex-direction: column;
  padding: 1px 0 40px 0;
  &__section {
    padding: 0 20px;
  }
}
@media screen and (min-width: 1023px) {
  .hd-sticky:hover {
    .sticky-c1 {
      background: #eee !important;
    }
    .sticky-c2 {
      background: #eee !important;
    }
    .v-data-table
      > .v-data-table__wrapper
      tbody
      tr:first-child:hover
      td:first-child {
      background: #eee !important;
    }
  }
}
// .box-page-new {
//   margin: 15px 0px;
// }
.v-text-field--filled:not(.v-text-field--single-line) input {
  margin-top: 0px !important;
}

.button-register {
  background-color: #00a4b3 !important;
  border-radius: 5px;
  color: white !important;
  font-size: 14px;
  text-align: center;
  text-transform: capitalize;
  height: 30px !important;
  min-width: 130px !important;
}

input,
select {
  color: $gray_2;

  option {
    color: $gray_2;
  }

  &.input-login {
    border: 1px solid #93969b;
    width: 100%;
    padding: 8px 16px;
    border-radius: 14px;
    background: white;
    font-size: 20px;
  }

  &.input-login-read {
    border: 1px solid #93969b;
    width: 100%;
    padding: 8px 16px;
    border-radius: 14px;
    background: #e9e9e9;
    font-size: 20px;
  }

  &:focus {
    outline: 1px solid $blue_2;
    outline: none;
  }
}

.hide-label {
  visibility: hidden;
}

.main-layout {
  background: #fff !important;
}

.title-page {
  color: $blue_1;
  margin-left: 15px;
  font-size: 24px;
  font-weight: 700;
}

a {
  text-decoration: none;
}

.containt {
  padding-bottom: 110px !important;
}

.image-profie {
  display: block;
  width: 150px;
  height: 150px;
  border-radius: 35px;
  object-fit: cover;
}

.item-info {
  color: #7b8cae;
}

.card--cart-order {
  padding: 0px 0px 15px 0px;
  border-radius: 4px;
  background: #fff;
  //   margin-top: 10px;
}

.card--cart {
  padding: 32px 15px;
}

.card--image {
  padding: 15px 15px;
  //   margin-top: 45px;
}

.blue-font {
  color: $blue_1;
  font-weight: 700;
  font-size: 16px;
}

.tabs {
  background: #e5e5e5 !important;
}

.content-table {
  padding: 15px;
  @media screen and (max-width: 960px) {
    padding: 5px 18px 18px 18px;
  }
}
@media screen and (max-width: 480px) {
  .content-table {
    padding: 0px !important;
  }
}

.fontSet {
  font-size: 14px !important;
}

.box-page-new {
  margin: 20px;
  border-radius: 4px !important;
}
.labeltitlemcu {
  font-size: 12px;
  color: #1a428a;
  margin-bottom: 0px;
}
.labeldatamcu {
  font-size: 12px;
  font-weight: 600;
  color: #1a428a;
  margin-bottom: 0px;
}
.mdifont {
  font-size: 15px;
  color: #1a428a;
}
</style>
<style>
@media screen and (min-width: 1023px) {
  .sticky-c1 {
    position: sticky !important;
    position: -webkit-sticky !important;
    left: 0;

    background: #fff;
  }
  .sticky-c2 {
    position: sticky !important;
    position: -webkit-sticky !important;
    left: 155px;

    background: #fff;
  }
  .sticky-h1 {
    position: sticky !important;
    position: -webkit-sticky !important;
    left: 0;
    z-index: 5;
    background: #ddd;
  }
  .sticky-h2 {
    position: sticky !important;
    position: -webkit-sticky !important;
    left: 155px;
    z-index: 5;
    background: #ddd;
  }
}
</style>
<style lang="scss">
.footerHide {
  background: none !important;
  align-items: center;
  width: 50px;
  font-size: 12px;
}
.footerHide > .v-data-footer__pagination {
  display: none !important;
}
.footerHide > .v-data-footer__icons-before {
  display: none !important;
}
.footerHide > .v-data-footer__icons-after {
  display: none !important;
}
.payment-info .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  font-size: 12px;
  vertical-align: baseline;
  padding-top: 6px;
  padding-bottom: 6px;
}
.v-toolbar__title {
  font-size: 16px;
}
.footer-payment {
  margin: 0 auto !important;
}
.footer-showing {
  font-size: 12px !important;
  margin: 0 auto !important;
}

.footerHide > .v-text-field .v-input__prepend-inner,
.v-text-field .v-input__append-inner {
  padding-left: 0 !important;
}
</style>
<style>
/* Hide scrollbar for Chrome, Safari and Opera */
.table-stick-addnew-exam .v-data-table__wrapper::-webkit-scrollbar {
  height: 8px;
  width: 0px;
  border: 1px solid #fff;
}

.table-stick-addnew-exam .v-data-table__wrapper::-webkit-scrollbar-track {
  border-radius: 0;
  background: #eeeeee;
}

.table-stick-addnew-exam .v-data-table__wrapper::-webkit-scrollbar-thumb {
  border-radius: 0;
  background: #b0b0b0;
}

/* Hide scrollbar for IE, Edge and Firefox */
.table-stick-addnew-exam .v-data-table__wrapper {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.super-dense > .v-data-table__wrapper > table > tbody > tr > td,
.super-dense > .v-data-table__wrapper > table > tbody > tr > th,
.super-dense > .v-data-table__wrapper > table > thead > tr > td,
.super-dense > .v-data-table__wrapper > table > thead > tr > th,
.super-dense > .v-data-table__wrapper > table > tfoot > tr > td {
  height: 35px !important;
}
.table-stick-addnew-exam .v-data-table__wrapper {
  overflow-x: auto;
  overflow-y: auto;
  height: 400px !important;
}
.table-stick-addnew-exam thead {
  position: sticky;
  inset-block-start: 0;
  top: 0;
  background: #ddd !important;
  font-weight: bold;
  color: #000 !important;
  z-index: 5;
}
</style>
