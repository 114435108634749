<template>
  <ul class="pagination">
    <li
      @click="tmpActivePage == 1 || tmpActivePage--"
      :class="{ 'd-none': totalPage == 1 }"
    >
      <div class="number" :class="{ disabled: tmpActivePage == 1 }">
        <v-icon>mdi-chevron-left</v-icon>
      </div>
    </li>
    <li
      v-for="(page, index) of totalPage"
      :key="index"
      class="d-none"
      :class="{
        active: tmpActivePage == index + 1,
        'd-block':
          tmpActivePage < 3
            ? index < 5
            : tmpActivePage + 3 > totalPage
            ? index + 1 > totalPage - 5
            : index + 1 > tmpActivePage - 3 && index + 1 < tmpActivePage + 3,
      }"
      @click="tmpActivePage = index + 1"
    >
      <div class="number">
        {{ page }}
      </div>
    </li>
    <li
      @click="tmpActivePage == totalPage || tmpActivePage++"
      :class="{ 'd-none': totalPage == 1 }"
    >
      <div class="number" :class="{ disabled: tmpActivePage == totalPage }">
        <v-icon>mdi-chevron-right</v-icon>
      </div>
    </li>
  </ul>
</template>

<script>
export default {
  props: {
    totalPage: {
      type: Number,
      default: 0,
    },
    activePage: {
      type: Number,
      default: 0,
    },
  },
  data: () => ({
    tmpActivePage: 0,
  }),
  created() {
    this.tmpActivePage = this.activePage;
  },
  watch: {
    activePage(val) {
      this.tmpActivePage = val;
    },
    tmpActivePage(val) {
      this.$emit("changePage", val);
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/color.scss";

.pagination {
  padding: 12px 0;
  display: flex;
  li {
    padding: 0 6px;
    display: inline-block;
    cursor: pointer;
    &:nth-child(1) {
      padding-left: 0;
    }
    &:nth-last-child(1) {
      padding-right: 0;
    }
    &.active {
      .number {
        background: $main_2;
        color: $gray_7;
      }
    }
    .number {
      width: 26px;
      height: 26px;
      background: white;
      border-radius: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 12px;
      box-shadow: 2px 4px 8px rgba(128, 128, 128, 0.474);
      &:hover {
        background: $main_2;
        color: $gray_7;
      }
      &.disabled {
        background: $gray_1;
        cursor: default;
        pointer-events: none;
      }
    }
  }
}
</style>
