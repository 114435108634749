<template>
  <div class="datepicker">
    <v-date-picker
      :landscape="$vuetify.breakpoint.smAndUp"
      :locale="$i18n.locale"
      :color="color"
      :min="minDate"
      :max="maxDate"
      :header-color="color"
      v-model="date"
      :allowed-dates="allowedDates"
      @update:picker-date="pickerUpdate($event)"
    ></v-date-picker>
    <div
      class="pa-3 d-flex footer flex-column flex-sm-row"
      :class="
        checkQueue && plant != '' ? 'justify-space-between' : 'justify-end'
      "
    >
      <p class="mb-3" v-if="checkQueue && plant != ''">
        {{
          queue > 0
            ? `* ${queue} ${$t("page.nearme.queue_this_date")}`
            : `* ${$t("page.nearme.no_queue")}`
        }}
      </p>
      <div class="d-flex justify-space-between justify-sm-end">
        <c-button
          outline
          class="pa-2 mr-3"
          small
          rounded
          @click="closeDatePicker"
        >
          {{ $t("cancel") }}
        </c-button>
        <c-button class="pa-2" small rounded @click="saveDatePicker">
          {{ $t("select") }}
        </c-button>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment-timezone";
import CButton from "@/components/v2/button/index.vue";
import API from "@/service/api_service";
import Auth from "@/service/auth_service";

export default {
  components: {
    CButton,
  },
  model: {
    prop: "",
  },
  props: {
    color: {
      type: String,
      default: "#00a4b3",
    },
    minDate: {
      type: String,
      default: "",
    },
    maxDate: {
      type: String,
      default: "",
    },
    value: {
      type: String,
      default: "",
    },
    enableDays: {
      type: Array,
      default: () => [],
    },
    holiday: {
      type: Array,
      default: () => [],
    },
    checkQueue: {
      type: Boolean,
      default: false,
    },
    plant: {
      type: [Number, String],
      default: "",
    },
  },
  data: () => ({
    pickerValue: "",
    availableDates: [],
    holidayThisMonth: [],
    startValue: "",
    queue: 0,
    date: "",
  }),
  watch: {
    enableDays: function () {
      this.pickerUpdate(this.pickerValue);
    },
    date: function (val) {
      if (this.checkQueue) this.callCheckQueue(val);
    },
  },
  created() {
    this.date = this.value;
    if (this.checkQueue) {
      this.callCheckQueue(this.date);
    }
  },
  methods: {
    async callCheckQueue(param) {
      try {
        const resp = await API.get(
          `${process.env.VUE_APP_API_TRUST}plant/get-lab-total-booking?plant_id=${this.plant}&date=${param}`,
          {
            Authorization: `Bearer ${Auth.getToken()}`,
          }
        );
        if (resp.statusCode == 200) {
          this.queue = resp.results[0].total_booking;
        }
      } catch (error) {
        console.log("Error 403! queue");
      }
    },
    closeDatePicker() {
      this.$emit("change");
    },
    saveDatePicker() {
      this.dateVal = this.date;
      this.$emit("changeValue", this.dateVal);
      this.$emit("change");
    },
    formatDate(date, format) {
      return moment(date).locale(this.$i18n.locale).format(format);
    },
    // start available date
    allowedDates(a) {
      return this.availableDates.includes(a);
    },
    pickerUpdate: function (val) {
      this.pickerValue = val;
      let totalDay = moment(val, "YYYY-MM").daysInMonth();

      let availableDates = [];

      let monthNow = moment().format("M");
      let monthSelected = moment(val).format("M");
      let day;

      if (monthNow == monthSelected) day = moment().format("D");
      else day = 1;
      for (let i = day; i <= totalDay; i++) {
        let date = moment(val, "YYYY-MM")
          .month(val.split("-")[1] - 1)
          .date(i)
          .format("YYYY-MM-DD");
        if (this.enableDays.includes(-1)) {
          availableDates.push(date);
        } else {
          if (this.enableDays.includes(moment(date).day()))
            availableDates.push(date);
        }
      }
      this.holidayThisMonth = this.holiday.filter(
        item => moment(item.date).format("YYYY-MM") == val
      );
      for (const holiday of this.holidayThisMonth) {
        const index = availableDates.indexOf(holiday.date);
        if (index !== -1) {
          availableDates.splice(index, 1);
        }
      }
      this.availableDates = availableDates;
    },
    // end available date
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/color.scss";

.datepicker {
  background: white;
  font-size: 12px;
  .footer {
    border-top: 1px solid $main_1;
  }
}
</style>
